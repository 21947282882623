<script>
import Swal from 'sweetalert2';
import { required, minLength, requiredIf,maxLength } from 'vuelidate/lib/validators';
import SideButtons from '../../../../../../components/side-buttons.vue';
export default {
    props:['selectedCategory','reset'],
    components:{SideButtons},
    data() {
        return {
            submitted:false,
            disabled:false,
            up:false,
            getEmit:false,
            select:false,
            add:false,
            duplicate:false,
            confirm:false,
            validate:false,
            edit:false,
            resetForm:false,
            SaveEdit:false,
            destroy:false,
            data:{
                category :{categoryCode:'', categoryName : '', categoryDescription : ''},
                sub_category :{subCategoryCode:false, subCategoryName : '', subCategoryDescription : ''},
                family :{familyCode:false, familyName : '', familyDescription : ''},
                subfamily :{subFamilyCode:false, subFamilyName : '', subFamilyDescription : ''},
                // unit :{id:'', name : '', description : ''},
            }
        }
    },
    watch:{
        reset:{
            handler(val){
               if(val) {this.resetData();this.add = false}
            }
        },
        selectedCategory:{
            handler(val){
                if(val){
                    this.data.category = val.category;
                    this.data.sub_category = val.subCategory;
                    this.data.family = val.family;
                    this.data.subfamily = val.subFamily;

                    this.select = true;
                    // console.log(val.subCategory.subCategoryCode.length);
                }
                else {this.resetData();this.select=false}
                
            }
        },
        add:{
            handler(val){
                this.resetForm=false;
                if(val && this.$can('create_product_categ')){
                    console.log("clearForm");
                    this.disabled = true;
                    this.resetForm=false;
                    this.resetData();
                }
                else if(val && !this.$can('create_product_categ')){ this.resetForm=true; Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')}
            }
        },
        duplicate:{
            handler(val){
                this.resetForm=false;
                if(val && this.$can('create_product_categ')){
                    console.log("dup Form");
                    this.disabled = true;
                    this.resetForm=false;
                    this.data.sub_category.subCategoryCode="";
                    this.data.family.familyCode="";
                    this.data.subfamily.subFamilyCode="";

                }
                else if(val && !this.$can('create_product_categ')){ this.resetForm=true; Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')}
            }
        },
        edit:{
            handler(val){
                this.resetForm=false;
                if(val && this.$can('edit_product_categ')){
                    this.disabled=true;
                    this.resetForm=false;
                    // this.edit=false;
                }
                else if(val && !this.$can('edit_product_categ')){ this.resetForm=true; Swal.fire('Accés Refusé','Vous n\'avez pas le droit d\'ajouté','error')}
            }
        },
        resetForm:{
            handler(val){
                if(val) {
                    console.log('reset');
                    this.resetData();
                    this.disabled=false;
                    this.add=false;
                    this.edit=false;
                    this.select=false;
                    this.duplicate=false;
                }
            }
        },
        destroy:{
            handler(val){
                    if(val){
                    // this.onValidate("Confirm");
                }
            }
        },
    },
    methods:{
        emitData(){
            this.$emit('getvalues',this.data);
        
        },
        resetData(btn = null){
            switch (btn) {
                case 'SubCat':
                    this.data.sub_category = {subCategoryCode: '', subCategoryName: '', subCategoryDescription: ''};
                    this.data.family       = {familyCode     : '', familyName     : '', familyDescription     : ''};
                    this.data.subfamily    = {subFamilyCode  : '', subFamilyName  : '', subFamilyDescription  : ''};
                    break;
                case 'Fam':
                    // this.data.sub_category = {subCategoryCode: '', subCategoryName: '', subCategoryDescription: ''};
                    this.data.family       = {familyCode     : '', familyName     : '', familyDescription     : ''};
                    this.data.subfamily    = {subFamilyCode  : '', subFamilyName  : '', subFamilyDescription  : ''};
                    break;
                case 'SubFam':
                    // this.data.sub_category = {subCategoryCode: '', subCategoryName: '', subCategoryDescription: ''};
                    // this.data.family       = {familyCode     : '', familyName     : '', familyDescription     : ''};
                    this.data.subfamily    = {subFamilyCode  : '', subFamilyName  : '', subFamilyDescription  : ''};
                    break;
            
                default:
                    this.data = {
                        category :{categoryCode:'', categoryName : '', categoryDescription : ''},
                        sub_category :{subCategoryCode:'', subCategoryName : '', subCategoryDescription : ''},
                        family :{familyCode:'', familyName : '', familyDescription : ''},
                        subfamily :{subFamilyCode:'', subFamilyName : '', subFamilyDescription : ''},
                    }
                    break;
            }
            
        },
        valide(){

            this.submitted = true;
            this.$v.$touch()
            if (this.$v.$invalid) {
                console.log("ERROR");
                this.submitted = false;
            }
            else {
                console.log("Valid");
                this.emitData();
                this.$v.$reset();
                this.submitted = false;
                this.add=false;
                this.disabled=false;
                this.duplicate=false;
                this.resetForm=true;
            }
        },
        update(){
            this.up = true;
            this.submitted = true;
            this.$v.$touch()
            if (this.$v.$invalid) {
                console.log("ERROR");
                this.submitted = false;
                this.edit = false;
                this.reset=true;
                this.disabled=false;
            }
            else {
                console.log("Valid");
                this.emitData();
                this.$v.$reset();
                this.$emit('updateData',this.up)
                this.submitted = false;
                this.edit = false;
                this.resetForm=true;
                this.disabled=false;
            }
        }


    },
    validations: {
        data:{
            category:{
                categoryName:{
                    required,
                    minLength: minLength(4),
                    maxLength: maxLength(50),
                },
            },
            sub_category:{
                subCategoryName: {
                    required:requiredIf(function() {
                        return this.data.family.name || this.data.subfamily.name
                    }),
                    minLength: minLength(4),
                    maxLength: maxLength(50),
                },
            },
            family:{
                familyName: {
                    required:requiredIf(function() {
                        return this.data.subfamily.name
                    }),
                    minLength: minLength(4),
                    maxLength: maxLength(50),
                },
            },
            subfamily:{
                subFamilyName: {
                    minLength: minLength(4),
                    maxLength: maxLength(50),
                },
            },
        }
        
  }
}
</script>
<template>
    <div class="row">
        <SideButtons :reset="resetForm" :Cat="true" :canEdit="!select" @info =" info = $event" @add="add = $event" @duplicate="duplicate = $event" @save="save = $event" @edit=" edit = $event" @resetEmit="resetForm = $event" @SaveEdit="SaveEdit = $event" @delete=" destroy = $event" />
        <div class="col-12">
            <b-card>
                <!-- <template #header>
                    <div class="row">
                        <div class="col-9 text-left">
                            <h5 class="mt-2">Catégorie Details</h5>
                        </div>
                    </div>
                </template> -->
                <b-card-text>
                    <fieldset :disabled="!disabled">
                        <table class="table">
                            <thead>
                                <th width="15%"></th>
                                <th width="25%">Designation</th>
                                <th v-if="edit" width="50%">Description</th>
                                <th v-else width="60%">Description</th>
                                <th v-if="edit" width="10%"></th>
                            </thead>
                            <tbody v-if="!edit">
                                <tr>
                                    <th scope="row">Catégorie<input type="hidden" v-model="data.category.categoryCode"> </th>
                                    <td>
                                        <input  type="text" :disabled="data.sub_category.subCategoryCode.length > 0" class="form-control" :class="{'is-invalid': $v.data.category.categoryName.$error,'bg-light':data.sub_category.subCategoryCode.length > 0}" v-model="data.category.categoryName"> 
                                        <div v-if="submitted && $v.data.category.categoryName.$error">
                                            <span class="text-danger" v-if="!$v.data.category.categoryName.required">Designation Catégorie est obligatoire</span>
                                            <span class="text-danger" v-if="!$v.data.category.categoryName.minLength">Designation Catégorie doit avoir au min {{$v.data.category.categoryName.$params.minLength.min}} lettres.</span>
                                        </div>
                                    </td>
                                    <td><input type="text" class="form-control" :disabled="data.sub_category.subCategoryCode.length > 0" :class="{'bg-light':data.sub_category.subCategoryCode.length > 0}" v-model="data.category.categoryDescription"></td>
                                </tr>
                                <tr>
                                    <th scope="row">Sous-Catégorie <input type="hidden" v-model="data.sub_category.subCategoryCode"> </th>
                                    <td>
                                        <input type="text" class="form-control" :disabled="data.family.familyCode.length > 0" :class="{'is-invalid': $v.data.sub_category.subCategoryName.$error,'bg-light':data.family.familyCode.length > 0}" v-model="data.sub_category.subCategoryName">
                                        <div v-if="submitted && $v.data.sub_category.subCategoryName.$error">
                                            <span class="text-danger" v-if="!$v.data.sub_category.subCategoryName.required">Designation Sous-Catégorie est obligatoire</span>
                                            <span class="text-danger" v-if="!$v.data.sub_category.subCategoryName.minLength">Designation Sous-Catégorie doit avoir au min {{$v.data.sub_category.subCategoryName.$params.minLength.min}} lettres.</span>
                                        </div>
                                    </td>
                                    <td><input type="text" class="form-control" :disabled="data.family.familyCode.length > 0" :class="{'bg-light':data.family.familyCode.length > 0}"  v-model="data.sub_category.subCategoryDescription"></td>
                                </tr>
                                <tr>
                                    <th scope="row">Famille<input type="hidden" v-model="data.family.familyCode"> </th>
                                    <td>
                                        <input type="text" class="form-control" :disabled="data.subfamily.subFamilyCode.length > 0" :class="{'is-invalid': $v.data.family.familyName.$error,'bg-light':data.subfamily.subFamilyCode.length > 0}" v-model="data.family.familyName">
                                        <div v-if="submitted && $v.data.family.familyName.$error">
                                            <span class="text-danger" v-if="!$v.data.family.familyName.required">Designation Famille est obligatoire</span>
                                            <span class="text-danger" v-if="!$v.data.family.familyName.minLength">Designation Famille doit avoir au min {{$v.data.family.familyName.$params.minLength.min}} lettres.</span>
                                        </div>
                                    </td>
                                    <td><input type="text" class="form-control" :disabled="data.subfamily.subFamilyCode.length > 0" :class="{'bg-light':data.subfamily.subFamilyCode}" v-model="data.family.familyDescription"></td>
                                </tr>
                                <tr>
                                    <th scope="row">Sous-Famille<input type="hidden" v-model="data.subfamily.subFamilyCode"> </th>
                                    <td>
                                        <input type="text" class="form-control" :class="{'is-invalid': $v.data.subfamily.subFamilyName.$error}" v-model="data.subfamily.subFamilyName">
                                        <div v-if="submitted && $v.data.subfamily.subFamilyName.$error">
                                            <span class="text-danger" v-if="!$v.data.subfamily.subFamilyName.required">Designation Sous-Famille est obligatoire</span>
                                            <span class="text-danger" v-if="!$v.data.subfamily.subFamilyName.minLength">Designation Sous-Famille doit avoir au min {{$v.data.subfamily.subFamilyName.$params.minLength.min}} lettres.</span>
                                        </div>
                                    </td>
                                    <td><input type="text" class="form-control" v-model="data.subfamily.subFamilyDescription"></td>
                                </tr>
                            </tbody>
                            
                            <tbody v-if="edit">
                                <tr>
                                    <th scope="row">Catégorie<input type="hidden" v-model="data.category.categoryCode"> </th>
                                    <td>
                                        <input  type="text"  class="form-control" :class="{'is-invalid': $v.data.category.categoryName.$error,}" v-model="data.category.categoryName"> 
                                        <div v-if="submitted && $v.data.category.categoryName.$error">
                                            <span class="text-danger" v-if="!$v.data.category.categoryName.required">Designation Catégorie est obligatoire</span>
                                            <span class="text-danger" v-if="!$v.data.category.categoryName.minLength">Designation Catégorie doit avoir au min {{$v.data.category.categoryName.$params.minLength.min}} lettres.</span>
                                        </div>
                                    </td>
                                    <td><input type="text" class="form-control" v-model="data.category.categoryDescription"></td>
                                    
                                </tr>
                                <tr>
                                    <th scope="row">Sous-Catégorie <input type="hidden" v-model="data.sub_category.subCategoryCode"> </th>
                                    <td>
                                        <input type="text" class="form-control"  :class="{'is-invalid': $v.data.sub_category.subCategoryName.$error,}" v-model="data.sub_category.subCategoryName">
                                        <div v-if="submitted && $v.data.sub_category.subCategoryName.$error">
                                            <span class="text-danger" v-if="!$v.data.sub_category.subCategoryName.required">Designation Sous-Catégorie est obligatoire</span>
                                            <span class="text-danger" v-if="!$v.data.sub_category.subCategoryName.minLength">Designation Sous-Catégorie doit avoir au min {{$v.data.sub_category.subCategoryName.$params.minLength.min}} lettres.</span>
                                        </div>
                                    </td>
                                    <td><input type="text" class="form-control" v-model="data.sub_category.subCategoryDescription"></td>
                                    <!-- <td v-if="edit&&data.sub_category.subCategoryCode"> <button style="border-radius:50%" class="btn btn-info" @click="resetData('SubCat')"><i class=" mdi mdi-autorenew"></i> </button> </td> -->
                                </tr>
                                <tr>
                                    <th scope="row">Famille<input type="hidden" v-model="data.family.familyCode"> </th>
                                    <td>
                                        <input type="text" class="form-control" :class="{'is-invalid': $v.data.family.familyName.$error}" v-model="data.family.familyName">
                                        <div v-if="submitted && $v.data.family.familyName.$error">
                                            <span class="text-danger" v-if="!$v.data.family.familyName.required">Designation Famille est obligatoire</span>
                                            <span class="text-danger" v-if="!$v.data.family.familyName.minLength">Designation Famille doit avoir au min {{$v.data.family.familyName.$params.minLength.min}} lettres.</span>
                                        </div>
                                    </td>
                                    <td><input type="text" class="form-control" v-model="data.family.familyDescription"></td>
                                    <!-- <td v-if="edit&&data.family.familyCode"> <button style="border-radius:50%" @click="resetData('Fam')" class="btn btn-info"><i class=" mdi mdi-autorenew"></i> </button> </td> -->
                                </tr>
                                <tr>
                                    <th scope="row">Sous-Famille<input type="hidden" v-model="data.subfamily.subFamilyCode"> </th>
                                    <td>
                                        <input type="text" class="form-control" :class="{'is-invalid': $v.data.subfamily.subFamilyName.$error}" v-model="data.subfamily.subFamilyName">
                                        <div v-if="submitted && $v.data.subfamily.subFamilyName.$error">
                                            <span class="text-danger" v-if="!$v.data.subfamily.subFamilyName.required">Designation Sous-Famille est obligatoire</span>
                                            <span class="text-danger" v-if="!$v.data.subfamily.subFamilyName.minLength">Designation Sous-Famille doit avoir au min {{$v.data.subfamily.subFamilyName.$params.minLength.min}} lettres.</span>
                                        </div>
                                    </td>
                                    <td><input type="text" class="form-control" v-model="data.subfamily.subFamilyDescription"></td>
                                    <!-- <td v-if="edit&&data.subfamily.subFamilyCode"> <button style="border-radius:50%" @click="resetData('SubFam')" class="btn btn-info"><i class=" mdi mdi-autorenew"></i> </button> </td> -->
                                </tr>
                            </tbody>
                            
                        </table>
                    </fieldset>
                    <div class="row mt-3">
                        <div class="col text-right">
                            <b-button v-if="add || duplicate"  @click="valide" variant="primary" class="btn-label mr-2">
                                <i class="far fa-save text-white label-icon"></i> Enregitrer
                            </b-button>
                            <b-button v-if="edit"  @click="update" variant="primary" class="btn-label mr-2">
                                <i class="far fa-save text-white label-icon"></i> Enregitrer
                            </b-button>

                            <b-button v-if="add || edit || duplicate"   @click="resetForm=true" variant="light" class="btn-label mr-2">
                                <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
                            </b-button>


                        </div>
                    </div>
                </b-card-text>
            </b-card>
            
        </div>
    </div>
</template>
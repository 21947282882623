<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import categsTable from "./components/categsTable.vue";
// import Catform from "./components/Catform.vue";

import { fetchProductsCategoriesListApi } from "@/api/common";
import Swal from "sweetalert2";
export default {
  import: {fetchProductsCategoriesListApi},
  page: {
    title: "Catégories de produits",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader,categsTable  },
  data() {
    return {
      selectedCat: null,
      tableData: [],
      List_Cat: [],
      rows:'',
      active:false,
      selectedRows:[],
      categorie:undefined,
      title: "Catégories de produits",
      items: [
        {
          text: "Master Data",
          to: {name:"master_data"},
        },
        {
          text: "Référentiel",
          to: {name:"master_data.referentiels"},
        },
        {
          text: "Gestion de Stock",
          to: {name:"master_data.referentiels.stock_management"},
        },
        {
          text: "Catégories de Produits",
          active: true,
        },
      ],
       currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.tableData.length;
    // fetch divisions from api
    // this.fetchCatList();
  },
   watch:{
      selectedRows(){
        console.log(this.selectedRows);
      },
      categorie:{
          handler(val){
            this.List_Cat.push({
                name:val.name,
                description:val.description  
            });
            console.log(this.List_Cat);
          }
          
          
      }
  },
  methods:{
    fetchCatList(){
      fetchProductsCategoriesListApi()
      .then(res => (this.data = res.data.original.list))
      .catch(() => {
          // error.response.status Check status code
          
      }).finally(() => {
          //Perform action in always
      });
    },

    getCat(){
      this.$http.get('/system/stock/product_categories/list')
      .then(response => {
        console.log(response.data);
          this.data = response.data;
          this.selectedRows.push(response.data[0]);
      })
      .catch(error => console.log(error))
    },
    refreshCat(){
      this.getCat();
    },
     deleteCat(typeName, typeUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer le type :" + typeName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/system/stock/product_categories/delete/" + typeUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  _this.fetchCatList();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    editCat(typeUid) {
      let loader = this.$loading.show();
      this.$http.post("/system/stock/product_categories/getSingleCategorieData/" + typeUid)
        .then((res) => {
          var status = res.data.original.status;
          var categorie = res.data.original.data.categorie;
          
          loader.hide();
          switch (status) {
            case 200:
                this.currentSelectedCat = categorie;
                this.updateTabsUi();
            break;

            case 500:
              this.$toast.warning(res.data.original.msg);
            break;
          }
        })
        .catch((error) => {
          this.$toast.error(error.message);
        })
        .finally(() => {});
    },

    handleLoadUomsDataToFormComponent(selectedCat){
      if(selectedCat){
        this.editCat(selectedCat.uuid);
        
      }
    },  

    updateTabsUi(){
      this.generalInfosSetUp = Boolean(this.currentSelectedCat.general_infos_setup);
      this.scopeSetUp = Boolean(this.currentSelectedCat.scope_setup);
      this.financeInfosSetUp = Boolean(this.currentSelectedCat.financial_infos_setup);
      this.priceListInfosSetUp = Boolean(this.currentSelectedCat.pricelist_infos_setup);
      this.synopticsInfosSetUp = Boolean(this.currentSelectedCat.synptics_infos_setup);
    },

    handleGeneralInfoStepSavedEvent(categorie){

      this.currentSelectedCat = categorie;

      this.generalInfosSetUp = true;
      // refresh contracts list
      this.shouldRefreshCatTable = true;
      // jump to next tab
      this.formStep = 1;
    },

  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <categsTable/>
  </Layout>
</template>
<script>
import categForm from "./categFrom";
import Swal from "sweetalert2";
import Dataset from 'vue-dataset/dist/es/Dataset.js'
import DatasetItem from 'vue-dataset/dist/es/DatasetItem.js'
import DatasetInfo from 'vue-dataset/dist/es/DatasetInfo.js'
import DatasetPager from 'vue-dataset/dist/es/DatasetPager.js'
// import DatasetSearch from 'vue-dataset/dist/es/DatasetSearch.js'
import DatasetShow from 'vue-dataset/dist/es/DatasetShow.js'


export default {

  components: {
    categForm,
    Dataset,
    DatasetItem,
    DatasetInfo,
    DatasetPager,
    // DatasetSearch,
    DatasetShow,
  },
  data() {
    return {
      tableData: [],
      title: "Catégories de produits",
      active:false,
      uoms: [],
      resetVals:false,
      items: [
        {
          text: "Paramètres",
          href: "javascript:void(0)",
        },
        {
          text: "Catégories de produits",
          active: true,
        },
      ],
      totalPages: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortDesc: false,
      sortBy: "name",
      fields: [
        { key: "name", sortable: true },
        { key: "type", sortable: true },
        { key: "classe", sortable: true },
      ],
      cols: [
        {
          name: 'Catégorie',
          field: 'category',
          sort: ''
        },
        {
          name: 'Sous-Catégorie',
          field: 'subCategory',
          sort: ''
        },
        {
          name: 'Famille',
          field: 'family',
          sort: ''
        },
        {
          name: 'Sous-Famille',
          field: 'subFamily',
          sort: ''
        }
      ],
      values:undefined,
      selectedRows:[],
      selectedIndex:-1,
      up:undefined
    };
  },
  watch:{
    values:{
      handler(val){
        console.log(val);
        if(val != undefined && !this.up) this.submitting();
      }
    },
    up:{
      handler(val){
        if(val) this.updating();
      }
    }
  },
  computed: {
    rows() {
      return this.uoms.length;
    },
  },
  mounted() {
    this.totalRows = this.tableData.length;
    this.refreshCategsTable();
  },
  methods: {
    selectRow(row,index){
      if(this.selectedIndex == index) {this.selectedRows = []; this.selectedIndex = -1;}
      else {
        this.selectedRows = [row];
        this.selectedIndex = index;
      }
    },

    deleteCateg(roleName, uomUid) {
      var _this = this;
      Swal.fire({
        title: "Êtes-vous sûr de supprimer la catégorie: " + roleName + "?",
        text: "Vous ne pourrez pas annuler cela!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Supprimer!",
      }).then((result) => {
        if (result.value) {
          this.$http
            .post("/system/stock/product_categories/delete/" + uomUid)
            .then((res) => {
              var status = res.data.original.status;
              switch (status) {
                case 200:
                    this.$toast.success(res.data.original.msg);
                  _this.refreshCategsTable();
                  break;

                case 500:
                  this.$toast.warning(res.data.original.msg);
                  break;
              }
            })
            .catch((error) => {
              this.$toast.error(error.message);
            })
            .finally(() => {});
        }
      });
    },
    refreshCategsTable() {
      
      this.$http
        .post("/stock/product_categories/list")
        .then((res) => {
          console.log(res)
          this.tableData = res.data.data
          this.totalPages = res.data.totalPages;
          this.resetVals=false;
        })
        .catch(() => {
          // error.response.status Check status code
        })
        .finally(() => {
          //Perform action in always
        });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    submitting(){
      this.$http.post('/stock/product_categories/store',this.values)
      .then(response => {
        console.log(response);
        this.values=undefined;
        var status = response.data.status;
              switch (status) {
                case 200:
                    this.$toast.success(response.data.msg);
                    this.refreshCategsTable();
                    this.resetVals=true;
                  break;

                case 500:
                  this.$toast.warning(response.data.msg);
                  break;
              }
        
      })
      .catch()
    },
    updating(){
      this.$http.post('/stock/product_categories/update',this.values)
      .then(response => {
        console.log(response);
        this.values=undefined;
        this.up=false;
        var status = response.data.status;
              switch (status) {
                case 200:
                    this.$toast.success(response.data.msg);
                    this.refreshCategsTable();
                    this.resetVals=true;
                    this.$v.$reset()
                  break;

                case 500:
                  this.$toast.warning(response.data.msg);
                  break;
              }
        
      })
      .catch(error => {this.up=false;})
    }
  },

};
</script>

<template>
    <div class="row">
      <div class="col-12">
        <div class="accordion mb-2" role="tablist">
          <b-card no-body class="mb-1">
            <b-card-header header-tag="header" class="p-1" role="tab">
              <b-button block @click="active = !active" style="background:#e5e9f0; color:black !important; border:none !important;">
                <div class="row">
                  <div class="col text-left mt-1"> <i class="mdi mdi-format-list-bulleted"></i> Liste des Catégories de Produit</div>
                  <div class="col text-right">
                    <i v-if="active" class="mdi mdi-chevron-up font-size-18"></i> 
                    <i v-else class="mdi mdi-chevron-down font-size-18"></i> 
                  </div>
                </div>
              </b-button>
            </b-card-header>
            <b-collapse id="accordion-1" :visible="active" accordion="my-accordion" role="tabpanel">
              <b-card-body class="shadow-lg">
              <div class=" mb-0 shadow">
                <dataset
                  v-slot="{ ds }"
                  :ds-data="tableData"
                  
                >
                  <div class="row">
                    <div class="col-md-6 mb-3 mb-md-0">
                      <dataset-show :ds-show-entries="5" />
                    </div>
                    <!-- <div class="col-md-6">
                      <dataset-search ds-search-placeholder="Search..." :wait="300" />
                    </div> -->
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="table-responsive">
                        <table class="table table-hover">
                          <thead>
                            <tr>
                              <th scope="col">ID</th>
                              <th v-for="(th, index) in cols" :key="th.field" :class="['sort', th.sort]" @click="click($event, index)">
                                {{ th.name }} <i class="gg-select float-right"></i>
                              </th>
                            </tr>
                          </thead>
                          <dataset-item tag="tbody">
                            <template #default="{ row, rowIndex }">
                              <tr @click="selectRow(row, rowIndex)" :class="{'bg-soft-info':selectedIndex == rowIndex}" style="cursor:pointer" >
                                <th scope="row">{{ rowIndex + 1 }}</th>
                                <th scope="row">{{ row.category.categoryName }}</th>
                                <td>{{ row.subCategory.subCategoryName }}</td>
                                <td>{{ row.family.familyName  }}</td>
                                <td>{{ row.subFamily.subFamilyName}}</td>
                              </tr>
                            </template>
                          </dataset-item>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex flex-md-row flex-column justify-content-between align-items-center">
                    <dataset-info class="mb-2 mb-md-0" />
                    <dataset-pager />
                  </div>
                </dataset>
              </div>
              </b-card-body>
            </b-collapse>
          </b-card>
        </div>
        
        <div class="mt-3">
            <categForm :selectedCategory="selectedRows[0]" @getvalues="values = $event" @updateData="up = $event" :reset="resetVals"/>
        </div>
      </div>
    </div>
</template>
<style>
  /* .pagi{
    margin-left:45%
  } */
</style>